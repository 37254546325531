/* eslint-disable no-useless-escape */
import CHOICES from 'CHOICES';

export const ALL = 'all';
export const AUTH_TOKEN = 'authToken';
export const DELETE_WARNING = {
	title: 'Are you sure?',
	text: 'Once deleted, you will not be able to recover this data!',
	icon: 'warning',
	buttons: true,
	dangerMode: true
};
export const CONVERSATION = 'conversation';
export const DETAIL = 'detail';
export const DATE = 'DATE';
export const LOCATION = 'LOCATION';
export const LIST = 'LIST';
export const READ = 'read';
export const UNREAD = 'unread';
export const USER = 'user';
export const WEEK = 'week';
export const FORTNIGHT = 'fortnight';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';
export const NEWEST = 'newest';
export const OLDEST = 'oldest';
export const PIPELINE = 'pipeline';
export const ASSIGNED_TO_ME = 'Assigned to me';
export const UNASSIGNED = 'Unassigned';
export const PRIORITY = 'Priority';
export const ARCHIVED_FILTER = 'Archived';
export const BOTS = 'Bots';
export const MANUALLY_CREATED = 'Manually added';
export const FACEBOOK = 'Facebook';
export const INSTAGRAM = 'Instagram';
export const WEBFORM = 'Webform';
export const FACEBOOK_LEAD_ADS = 'Facebook lead gen';

export const USERTYPE = [
	{ value: 1, label: 'Admin' },
	{ value: 2, label: 'Operations' },
	{ value: 3, label: 'Sales' },
	{ value: 4, label: 'Team Admin' },
	{ value: 5, label: 'Team Member' }
];
export const SORT_DATA = [
	{ label: 'Newest', value: NEWEST },
	{ label: 'Oldest', value: OLDEST },
	{ label: 'Read', value: READ },
	{ label: 'Unread', value: UNREAD }
];
export const FILTER_DATA = [
	{ label: 'Unassigned', value: UNASSIGNED },
	{ label: 'Priority', value: PRIORITY },
	{ label: 'Archived', value: ARCHIVED_FILTER }
];

export const CHANNEL_DATA = [
	{ label: 'Bots', value: BOTS },
	{ label: 'Manually added', value: MANUALLY_CREATED },
	{ label: 'Facebook', value: FACEBOOK },
	{ label: 'Instagram', value: INSTAGRAM },
	{ label: 'Webform', value: WEBFORM },
	{ label: 'FaceBook Lead Ads', value: FACEBOOK_LEAD_ADS }
];
export const READONLY = 6;
export const ALL_ENQUIRIES = 'All enquiries';
export const ASSIGNED_ME = 'Assigned to me';
export const WON_ENQUIRIES = 'Won enquiries this month';
export const LOST_ENQUIRIES = 'Lost enquiries this month';
export const WON_ENQUIRIES_LAST_30_DAYS = 'Won enquiries last 30 days';
export const LOST_ENQUIRIES_LAST_30_DAYS = 'Lost enquiries last 30 days';
export const ARCHIVED = 'Archived enquiries';

export const fileLargeMessage = (size = '2MB') =>
	`File size too big, Please upload less then ${size}`;
export const invalidImageFormat = () => 'File type is invalid';

export const ALL_SALES = 'All sales';
export const MY_SALES = 'My sales';
export const FILE_EXTENSION = ['pdf', 'xlsx', 'doc', 'docx', 'rtf', 'text', 'wpd', 'heic'];
export const URL_REGULAR_VALIDATOR =
	/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const marketingResourcesSocialMediaTypes = {
	LINKEDIN: 1,
	FACEBOOK: 2,
	TWITTER: 3,
	INSTAGRAM: 4
};

export const INBOX_NEW = CHOICES.CardStatusChoice.NEW;
export const INBOX_OPEN = CHOICES.CardStatusChoice.OPEN;
export const INBOX_CLOSE = CHOICES.CardStatusChoice.CLOSE;
export const INBOX_ALL = 0;
export const INBOX_MINE = 2;
export const INBOX_UNASSIGNED = 3;
export const INBOX_ARCHIVED = 4;
export const INBOX_UNARCHIVED = 5;

export const INBOX_MAIN_TAB_ALL = 0;
export const INBOX_MAIN_TAB_MY = 1;

export const SEARCH_CAT_ALL = 'All';
export const SEARCH_CAT_NAME = 'Name';
export const SEARCH_CAT_EMAIL = 'Email';
export const SEARCH_CAT_ENQUIRY_TYPE = 'Enquiry type';
export const SEARCH_CAT_EXTRA_INFO_NAME = 'Extra info field names';
export const SEARCH_CAT_EXTRA_INFO_DATA = 'Extra info field data';

export const USER_TYPE = 'user';
export const PIPELINE_TYPE = 'stage';
export const STAGE_TYPE = 'stage';
export const DATE_TYPE = 'date';
export const ENQUIRY_TYPE = 'enquiry';
export const SUB_ENQUIRY_TYPE = 'subEnquiry';
export const BOT_TYPE = 'bot';
export const EXTRA_TYPE = 'extra';

export const SEARCH_CAT_LIST = [
	{ value: SEARCH_CAT_ALL, label: SEARCH_CAT_ALL },
	{ value: SEARCH_CAT_NAME, label: SEARCH_CAT_NAME },
	{ value: SEARCH_CAT_EMAIL, label: SEARCH_CAT_EMAIL },
	{ value: SEARCH_CAT_ENQUIRY_TYPE, label: SEARCH_CAT_ENQUIRY_TYPE },
	{ value: SEARCH_CAT_EXTRA_INFO_NAME, label: SEARCH_CAT_EXTRA_INFO_NAME },
	{ value: SEARCH_CAT_EXTRA_INFO_DATA, label: SEARCH_CAT_EXTRA_INFO_DATA }
];
export const CURRENCY = {
	USD: '$',
	GBP: '£',
	EUR: '€',
	AUD: '$'
};

export const ENQUIRIES = 'enquiries';
