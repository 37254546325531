/* eslint-disable no-unused-vars */
/* eslint-disable */
import React from 'react';
export const isEmpty = (val) => {
	/*
	test results
	--------------
	[]        true, empty array
	{}        true, empty object
	null      true
	undefined true
	""        true, empty string
	''        true, empty string
	0         false, number
	true      false, boolean
	false     false, boolean
	Date      false
	function  false
	*/
	if (val === undefined) return true;

	if (
		typeof val === 'function' ||
		typeof val === 'number' ||
		typeof val === 'boolean' ||
		Object.prototype.toString.call(val) === '[object Date]'
	)
		return false;

	if (val == null || val.length === 0)
		// null or 0 length array
		return true;

	if (typeof val == 'object') if (Object.keys(val).length === 0) return true;

	if (typeof val === 'string') if (val.trim() === '') return true;

	return false;
};

export const sanatizeObjectData = (data, key, initial = '') => {
	if (!isEmpty(data)) {
		return data[key];
	}
	return initial;
};

export const sanatizeSelectData = (data, subLabel, subValue) => {
	let obj = {};
	if (!isEmpty(data)) {
		obj.label = data[subLabel];
		obj.value = data[subValue];
		return obj;
	}
	return obj;
};

export const arrayMoveMutate = (array, from, to) => {
	const startIndex = to < 0 ? array.length + to : to;
	const item = array.splice(from, 1)[0];
	array.splice(startIndex, 0, item);
};

export const arrayMove = (array, from, to) => {
	array = array.slice();
	arrayMoveMutate(array, from, to);
	return array;
};

export const getFormatedDate = (d, separator = '/') => {
	const date = new Date(d);
	return `${getFullDate(date.getDate())}${separator}${getFullMonth(
		date.getMonth() + 1
	)}${separator}${date.getFullYear()}`;
};

export const getFormatedTime = (d) => {
	const date = new Date(d);
	return `${date.getHours()}:${date.getMinutes()}`;
};

export const getFullMonth = (val) => {
	if (val < 10) return `0${val}`;
	return val;
};

export const getFullDate = (val) => {
	if (val < 10) return `0${val}`;
	return val;
};

export const getFullHour = (d) => {
	const date = new Date(d);
	const hour = `${date.getHours()}`;
	return `${hour < 10 ? `0${hour}` : hour}`;
};

export const getFullminute = (d) => {
	const date = new Date(d);
	const min = `${date.getMinutes()}`;
	return `${min < 10 ? `0${min}` : min}`;
};

export const toCapitalize = (value) => {
	const val = value.toLowerCase();
	return val.charAt(0).toUpperCase() + val.slice(1);
};

export const lowerCase = (value) => {
	return value.toLowerCase();
};

export const numberFormatter = (num, digits) => {
	var si = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' }
	];
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var i;
	for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const getNameInitial = (text, singleChar) => {
	const name = text === '' || text === null || !text ? 'NA' : text.split(' ');
	if (isEmpty(text)) {
		return 'NA';
	}
	const [firstName, lastName] = name;
	if (isEmpty(firstName) && isEmpty(lastName)) {
		return 'NA';
	}
	if (isEmpty(firstName) && !isEmpty(lastName)) {
		return `${lastName[0].toUpperCase()}`;
	}
	if (firstName && lastName) {
		const name = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
		if (singleChar) return `${firstName[0].toUpperCase()}`;
		return name;
	} else {
		return `${firstName[0].toUpperCase()}`;
	}
};

export const Tooltip = ({ text, floatRight = false }) => {
	const name = text === '' || text === null ? 'NA' : text;
	return (
		<div className={`tooltip_default tooltip_wrapper ${floatRight ? 'tooltip_right' : ''}`}>
			<p className={`${floatRight ? 'tooltip_right_arrow' : 'tooltip_wrapper_arrow'}`}></p>
			<p className="tooltip_text">{name}</p>
		</div>
	);
};

export const validEmail =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const imageRegex = /\.(gif|jpg|jpeg|tiff|png)$/i;

export const isValidEmail = (email) => {
	return validEmail.test(email);
};
export const truncate = (input, size = 5) =>
	input.length > size ? `${input.substring(0, size)}...` : input;

export const numberWithCommas = (value) => {
	return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getIdWithoutPrefix = (Id) => {
	const splittedId = Id.split('-');
	splittedId.splice(0, 1);
	return splittedId.join('-');
};

export const getLastChar = (str) => {
	return str[str.length - 1];
};

export const toolTipOnMouse = (element, position = 'left') => {
	window.onmousemove = function (e) {
		var x = e.clientX,
			y = e.clientY;
		element.style.top = y + 20 + 'px';
		if (position === 'left') element.style.left = x - 30 + 'px';
		if (position === 'right') element.style.left = x - 90 + 'px';
	};
};

export const popString = (string = '', count = 0, delimeter = '') => {
	const splitString = string.split(delimeter);
	splitString.forEach((string, idx) => {
		if (idx < count) {
			splitString.pop();
		}
	});
	return splitString.join(delimeter);
};

export const getLastChars = (string, count) => {
	return string.substring(string.length - count, string.length);
};

export const Currency = {
	null: '',
	2: 'GBP',
	3: 'USD',
	GBP: 'GBP',
	'\u00a3': 'GBP',
	$: 'USD',
	USD: 'USD',
	AUD: 'AUD',
	EUR: 'EUR',
	'\u20ac': 'EUR'
};

export const currencySymbol = {
	GBP: '£',
	USD: '$',
	AUD: '$',
	EUR: '€',
	EURO: '€'
};

export const CurrencyCode = {
	1: '&#36;',
	2: '&#163;',
	3: 'A$',
	4: '&#128;'
};

export const isMobile = () => {
	return window.innerWidth <= 500;
};

export const isIPad = () => {
	return window.innerWidth <= 1024 && window.innerWidth >= 834;
};

export const isInViewport = (elem) => {
	var bounding = elem.getBoundingClientRect();
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

export const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export const validNumber = /[A-Za-z!,@€£#$%^&*()]/g;

export const toAlpha = (val) => {
	return val.replace(/[^a-zA-Z]+/, '');
};

export const getAlphaInitial = (num) => {
	if (num < 0 || num > 26 || typeof num !== 'number') {
		return -1;
	}
	const leveller = 65;
	return String.fromCharCode(num + leveller);
};

export const getFilteredName = (name) => {
	const firstOccurence = name.indexOf('-');
	const filteredString = name.slice(0, firstOccurence);
	return filteredString;
};

export const getFirstLetterCapital = (val) => {
	if (!val) {
		return val;
	}
	const firstLetterCapital = val.toLowerCase().charAt(0).toUpperCase() + val.toLowerCase().slice(1);
	return firstLetterCapital;
};
export const getCtaFirstLetterCapital = (val) => {
	const ctaFirstLetterCapital = val.toLowerCase().charAt(0).toUpperCase() + val.slice(1);
	return ctaFirstLetterCapital;
};

export const emptyNoteRaw = {
	blocks: [
		{
			key: 'd8nim',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {}
		}
	],
	entityMap: {}
};

export const isEmptyValue = (value) => {
	if (!value) return true;
	const replaced = value
		.replace(/\n/gi, '')
		.replace(/&nbsp;/gi, '')
		.replace(/<p><\/p>|<\/p>&nbsp;<\/p>/gi, '');
	return isEmpty(replaced);
};

export const getQueryParam = (key) => {
	if (!key) return;
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(key);
};

export const getMenuDropdownPosition = () => {
	let positions = 'auto';
	if (window.innerWidth <= 2500) {
		positions = 'top';
	}
	return positions;
};

export const isValidUrl = (value) => {
	const expression =
		/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,6}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
	const regex = new RegExp(expression);
	const url = value.match(regex);
	if (url === null) return false;

	return true;
};

export const isValidDomain = (value) => {
	if (value.startsWith('https://')) {
		return true;
	} else {
		return false;
	}
};

export const isInputZero = (value) => {
	const input = parseInt(value);
	if (input <= 0) {
		return true;
	}
	return false;
};

export const getObjectEntries = (obj) => {
	var ownProps = Object.keys(obj),
		i = ownProps.length,
		resArray = new Array(i);
	while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

	return resArray;
};

export const sanatizeSingleAttachmentName = (attachment) => {
	const name_from_url = attachment.split('/');
	if (attachment.includes('__')) {
		const fileName = name_from_url[name_from_url.length - 1].split('__');
		return fileName[fileName.length - 1];
	}
	const fileName = name_from_url[name_from_url.length - 1].split('_');
	return fileName[fileName.length - 1];
};

export const sanatizeAttachmentName = (attachments) => {
	if (Array.isArray(attachments)) {
		const names = attachments.map((item) => sanatizeSingleAttachmentName(item));
		return names;
	}
	return [];
};

export const isContentvalueEmpty = (value) => {
	if (typeof value === 'string' && value) {
		return !value.trim();
	}
	return false;
};

export const isNumber = (value) => {
	var pattern = /^\d+$/;
	return pattern.test(value);
};

export const isInt = (value) => {
	var pattern = /^\d*\.?\d*$/;
	return pattern.test(value);
};

export const isPhoneValid = (value) => {
	var pattern = /^[\+\d]?(?:(?!.*--)[\d-\s]*)$/;
	return pattern.test(value);
};
export const extractTextFromHtml = (message) => {
	if (message) {
		const regex1 = /<div class="pre">(?:<strong>&nbsp;<\/strong>|&nbsp;)<\/div>/gi;
		const msg = message.replace(regex1, '\n\n');
		let temp = document.createElement('div');
		temp.innerHTML = msg;
		let sanitized = temp.textContent || temp.innerText;
		return sanitized;
	}
	return '';
};
